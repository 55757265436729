.App {
  text-align: center;
  background-color: #e3eef3;
  min-height: 95vh;
}
.Header {
  text-align: center;
  background-color: #e3eef3;
  min-height: 5vh;
  max-width: 100%;
}

body {
  max-width: 100%;
  margin: 0 auto;
}

#root {
  min-height: 100vh;
}

/******************************************************************************/
/* Navbar */
.appNav {
  margin: 0 16px 0 0;
}

.rightNav {
  float: right;
}
.leftNav {
  float: left;
}

.pageButtonBold {
  font-weight: 800;
}

.buttonContainer {
  background-color: #fff;
  border-radius: 16px;
  display: inline-block;
}

/* .buttonContainerTop {
  margin-left: 155px;
} */

.connectButton {
  background-color: rgb(253, 234, 241) !important;
  border-radius: 16px !important;
  border: 1px solid #fff !important;
  color: rgb(213, 0, 102) !important;
  min-width: 95%;
  min-height: 3.5vh;
}

.connectButtonContainer {
  display: inline-block;
  margin-right: 10px;
}

.connectButton:hover {
  border-color: rgb(213, 0, 102) !important;
}

.hoverBold:hover {
  font-weight: 800;
}

.hoverBold .nav-link.active {
  font-weight: 800;
}

/******************************************************************************/
/* Swap Container */
.appBody {
  height: 100%;
  padding-top: 80px;
  max-width: 900px;
  margin: 0 auto;
}

.swapContainer {
  /* background-color: rgb(203, 231, 240); */
  background-color: white;
  max-width: 360px;
  height: 280px;
  margin: 10px auto;
  top: 50%;
  border-radius: 24px;
  border: 1px solid #fff;
  padding: 6px;
}

.connectContainer {
  background-color: white;
  max-width: 360px;
  margin: 10px auto;
  top: 50%;
  border-radius: 24px;
  border: 1px solid #fff;
  padding: 6px;
}

.swapHeader {
  text-align: left;
  padding: 4px 20px 0px 20px;
}

.swapText {
  color: #000;
  font-size: 20px;
}

.gearContainer {
  float: right;
  font-size: 18px;
}

.walletContainer {
  float: right;
  font-size: 15px;
  background-color: rgb(251, 221, 98);
  border-radius: 20px;
  padding: 5px;
}
.walletConnect {
  font-size: 15px;
  background-color: rgb(251, 221, 98);
  border-radius: 20px;
  border: 1px solid #fff !important;
}

.walletConnect:hover {
  border-color: rgb(213, 0, 102) !important;
}
/******************************************************************************/
/* Currency Input */
.currencyInput {
  background-color: rgb(217, 236, 242);
  margin: 10px !important;
  padding: 10px;
  border-radius: 20px;
}
.currencyInput:hover {
  border-color: #65cdee !important;
  border: 1px solid;
}
::placeholder {
  opacity: 0.25;
}
.numberContainer {
  text-align: left;
}
.spinnerContainer {
  height: 55px;
  line-height: 55px;
}
.currencyInputField {
  font-size: 15px;
  display: inline-block;
  width: 100%;
  border: none;
  background-color: rgb(217, 236, 242);
}
.tokenContainer {
  text-align: right;
}
.tokenName {
  font-size: 15px;
  display: block;
  color: #000;
}
.balanceContainer {
  display: block;
}
.balanceAmount {
  width: 100%;
  font-size: 11px;
  display: inline;
  color: #000;
}

/******************************************************************************/
/* Ratio */
.ratioContainer {
  text-align: left;
  margin-left: 10px;
  height: 28px;
}

/******************************************************************************/
/* Swap Button */
.swapButtonContainer {
  margin: 10px;
}
.swapButton {
  width: 100%;
  height: 55px;
  line-height: 55px;
  font-size: 24px;
  color: #fff;
  border-radius: 20px;
  background-color: #dd2f81;
  cursor: pointer;
}

/******************************************************************************/
/* Config Modal */

.modaly {
  position: fixed;
  left: 0;
  top: -510px;
  right: -110px;
  bottom: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  color: rgb(86, 90, 105);
}

.modal-content {
  background-color: #fff;
  width: 300px !important;
  padding: 0px 20px;
  background-color: rgb(237, 238, 242) !important;
  border: 1px solid rgb(206, 208, 217) !important;
  border-radius: 12px !important;
}

.modalTitle {
  margin: auto;
}

.titleHeader {
  margin-top: 5px;
  margin-left: -10px;
}
.labelField {
  padding-left: 0px !important;
}
.fieldContainer {
  padding-left: 0px !important;
}
.inputField {
  padding-left: 10px;
  /* border-radius: 5px; */
  border: 1px solid #65cdee;
  height: 1rem;
  margin: 10px 0;
  width: 100%;
}

.inputFieldUnitsContainer {
  padding-left: 0px !important;
  padding-top: 14px;
}

.addButton {
  float: right;
  background-color: rgb(251, 221, 98);
  border-radius: 5px;
}
.smallButton {
  background-color: rgb(251, 221, 98);
  border-radius: 5px;
  text-align: center;
  border: 1px solid rgb(206, 208, 217) !important;
  color: #000;
}
.smallButtonCancel {
  background-color: rgb(251, 98, 126);
  border-radius: 5px;
}
.smallButtonWithdraw {
  background-color: rgb(98, 251, 113);
  border-radius: 5px;
}

.yourPosContainer {
  background-color: white;
  max-width: 500px;
  margin: 25px auto;
  top: 50%;
  border-radius: 24px;
  border: 1px solid #fff;
  padding: 6px;
}

/******************************************************************************/
/* Table */

thead {
  background-color: rgb(203, 231, 240);
  /* border-radius: 24px; */
  /* padding: 6px; */
}

tbody {
  border-bottom: 1px solid lightgray;
}

th {
  border-bottom: 1px solid white;
  border-right: 1px solid lightgray;
  padding: 2px 4px;
}

/******************************************************************************/
/* Stake Modal */
.modal-class {
  background-color: rgba(0, 0, 0, 0.5);
  position: fixed;
  left: 0;
  top: -200px;
  right: 0px;
  bottom: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  color: rgb(86, 90, 105);
}
.modal-content {
  background-color: #fff;
  width: 300px !important;
  padding: 10px 25px;
  background-color: rgb(237, 238, 242) !important;
  border: 1px solid rgb(206, 208, 217) !important;
  border-radius: 12px !important;
}
.fieldContainer {
  padding-left: 0px !important;
}
.inputField {
  padding-left: 10px;
  border-radius: 10px;
  border: 1px solid #65cdee;
  height: 1.7rem;
  margin: 10px 0;
  width: 100%;
}

input[type="number"] {
  width: 100px;
}
.inputFieldUnitsContainer {
  padding-left: 0px !important;
  padding-top: 14px;
}
.pinkButton {
  width: 100%;
  height: 45px;
  line-height: 45px;
  font-size: 24px;
  color: #fff;
  border-radius: 20px;
  background-color: #dd2f81;
  cursor: pointer;
}
.orangeMiniButton {
  width: 100%;
  height: 25px;
  line-height: 25px;
  font-size: 12px;
  color: #000;
  border-radius: 20px;
  background-color: #fff;
  cursor: pointer;
}
.orangeMiniButton:hover {
  color: #fff;
  background-color: #f90;
}

.select {
  background-color: rgb(251, 221, 98);
  border-radius: 5px;
  margin-bottom: 5px;
}

@media (min-width: 767.98px) {
  /* .connectContainer {
    max-width: 360px;
    margin: 1px auto;

    border-radius: 24px;
    border: 1px solid #fff;
    padding: 1px;
  } */
}
